import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import { CountUp } from '../modules/countUp.js'
import { gsap } from 'gsap'
import Committees from '../modules/committees'
// import Anchors from '../modules/anchors'
import SteeringCommitteeSwiper from '../modules/steering-committee-swiper'

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      counters: viewStorage.current.querySelectorAll('.js-count-up'),
      sectionNumberCols: viewStorage.current.querySelectorAll('.section--numbers .col'),
      swiperSteeringCommittee: viewStorage.current.querySelector('.SteeringCommittee .swiper'),
      swiperCitations: viewStorage.current.querySelector('.swiperCitations'),
      sectionCommittee: viewStorage.current.querySelector('.section--committee')
    }

    this.init()
    this.onEnterCompleted()
  }

  init() {
    const { 
      counters = null,
      swiperCitations = null,
      sectionNumberCols = null,
      swiperSteeringCommittee = null,
      sectionCommittee = null
    } = this.DOM

    // Numbers
    if (sectionNumberCols && sectionNumberCols.length) this.setNumber()

    // Swiper
    if (swiperCitations) this.mySwiper = new WknSwiper(swiperCitations)

    // Committee
    if (sectionCommittee) this.committeesModule = new Committees(sectionCommittee)

    if (swiperSteeringCommittee) this.steeringCommittee = new SteeringCommitteeSwiper(swiperSteeringCommittee) 
  }

  countStart(){
    const { counters, sectionNumberCols } = this.DOM

    const options = {
      useEasing: true,
      useGrouping: true,
      separator: " ",
      decimal: "."
    }

    gsap.to(sectionNumberCols, { opacity: 1})
      
    counters.forEach( (item) => {
      const value = item.dataset.value
      const counter = new CountUp(item, value, options)
      counter.start()
    })
  }

  setNumber() {
    const { sectionNumberCols } = this.DOM

    gsap.set(sectionNumberCols, { opacity: 0})

    new Waypoint({
      element: sectionNumberCols[0],
      handler: () => this.countStart(),
      offset: '50%'
    })
  }

  setTabs() {
    // this.DOM.toggleCommittee = viewStorage.current.querySelectorAll('.toggle-committee--menu_btn')
    // this.DOM.resultsCommittee = viewStorage.current.querySelectorAll('.toggle-committee--tab')

    // Committee toggle
    const currentCommittee = ''
    const currentCommittee2 = ''
    const currentCommitteeResults = ''

    this.DOM.toggleCommittee.forEach(tab => {
        tab.addEventListener('click', (e) => {
          e && e.preventDefault()

          // Remove active classNames
          this.DOM.toggleCommittee.forEach(tab => tab.classList.remove('--active'))
          this.DOM.resultsCommittee.forEach(tab => tab.classList.remove('--active'))

          // Active button
          e.currentTarget.classList.add('--active')

          const currentCommittee = e.currentTarget.getAttribute('data-tab')
          const currentCommitteeResults = document.getElementById(currentCommittee)
          currentCommitteeResults.classList.add('--active')
        })
      }
    )
  }

  onLeaveCompleted() {
    this.mySwiper && this.mySwiper.destroy()
    this.committeesModule && this.committeesModule.destroy()
    this.steeringCommittee && this.steeringCommittee.destroy()
  }
}
