import { isDEVMODE, globalStorage, domStorage } from '../_globals'
import Search from './search'

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggle = this.DOM.el.querySelector('.Nav__toggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')
    // this.DOM.toggleLanguage = this.DOM.el.querySelectorAll('.language-toggle a')

    this.setEvents()
  }

  setEvents() {
    const { overlay } = domStorage
    const { toggle } = this.DOM
    // const { toggleLanguage } = this.DOM

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.menuOpen === true ? this.close() : this.open()
    })

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // Search
    globalStorage.search = new Search()

    // overlay
    overlay.addEventListener('click', (e) => {
      if (globalStorage.menuOpen) this.close()
    })

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)

    // Language toggle
    // toggleLanguage.forEach(
    //   link => {
    //     link.addEventListener('click', (e) => {
    //       // const isActive = e.currentTarget.classList.contains('--active')
    //       toggleLanguage.forEach(
    //         link => link.classList.remove('--active')
    //       )
    //       e.currentTarget.classList.add('--active')
    //     })
    //   }
    // )

  }

  open() {
    // if (isDEVMODE) console.log('Open Menu')

    globalStorage.menuOpen = true
    domStorage.body.classList.add('showMenu')
    domStorage.body.style.overflow = 'hidden'

  }

  close() {
    // if (isDEVMODE) console.log('Close Menu')

    globalStorage.menuOpen = false
    domStorage.body.classList.remove('showMenu')
    domStorage.body.style.overflow = ''
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        // if (isDEVMODE) console.log('updateHeaderResize')
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
