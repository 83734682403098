import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class viewAnims {

  constructor(container = viewStorage.current) {

    viewStorage.hasAnims = true

    this.DOM = {
      textAnim: container.querySelectorAll('[data-textanim]')
    }

    this.init()

  }

  init() {
    if (isDEVMODE) console.log('Init viewAnims')
    this.initTextAnim()
  }

  initTextAnim() {
    const { textAnim } = this.DOM

    this.textAnims = []

    textAnim.forEach(item => {
      item.textsplit = new SplitText(item, {type: 'words', wordsClass: 'word__wrapper'})
      item.textsplitwords = new SplitText(item, {type: 'words', wordsClass: 'word'})
      item.textanimTL = gsap.timeline({ paused: true, immediateRender: true })
      item.textanimTL.fromTo(item.textsplitwords.words, { yPercent: 120 }, { yPercent: 0, stagger: 0.03, duration: 1.2, ease: 'power4.out' }, 0)
      item.textanimST = new ScrollTrigger.create({
        trigger: item,
        // markers: isDEVMODE,
        start: 'top bottom-=25%',
        once: true,
        onEnter: () => item.textanimTL.restart()
      })
      this.textAnims.push(item)

    });
  }

  destroy() {
    if (isDEVMODE) console.log('Destroy viewAnims')
    this.textAnims.forEach(item => {
      item.textsplit = null
      item.textsplitwords = null
      item.textanimTL.kill(true)
      item.textanimST.kill(true)
    });
  }
}
