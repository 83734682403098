export default class StepsMenu {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.steps = this.DOM.container.querySelectorAll('.steps--item')
    this.DOM.stepsNavItems = this.DOM.container.querySelectorAll('.steps--item__nav')

    if (!this.DOM.steps && !this.DOM.steps.length) return

    this.setEvents()
  }

  setEvents() {
    const { stepsNavItems } = this.DOM

    this.displayStep = this.displayStep.bind(this)
    this.hideStep = this.hideStep.bind(this)

    stepsNavItems.forEach(item => {
      item.addEventListener('click', this.displayStep)
      item.addEventListener('mouseenter', this.displayStep)
      item.addEventListener('mouseleave', this.hideStep)
    })
  }

  displayStep(e) {
    e && e.preventDefault()

    const { steps } = this.DOM
    const parent = e.currentTarget.parentNode

    steps.forEach(step => step.classList.remove('--active'))

    !parent.classList.contains('--active') && parent.classList.add('--active')
  }

  hideStep(e) {
    e && e.preventDefault()

    const { steps } = this.DOM
    const parent = e.currentTarget.parentNode

    parent.classList.remove('--active')
  }

  destroy() {
    const { stepsNavItems = null } = this.DOM

    if (!stepsNavItems && !stepsNavItems.length) return

    stepsNavItems.forEach(item => {
      item.removeEventListener('click', this.displayStep)
      item.removeEventListener('mouseenter', this.displayStep)
      item.removeEventListener('mouseleave', this.hideStep)
    })
  }
}
