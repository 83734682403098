import { globalStorage } from './_globals'

export const updatePageInfo = (doc) => {
  var json_data = doc.getElementById('json_data');
  globalStorage.pageData = JSON.parse(json_data.innerHTML);
  console.log(globalStorage.pageData);
  //document.querySelectorAll();
}

export const updateLanguages = () => {
  var current_page_other_languages = globalStorage.pageData.languages.current_page_other_languages;

  var languageLinks = document.querySelectorAll('.languageLink');
  languageLinks.forEach(function (languageLink) {
    languageLink.style.display = 'none';
  });

  for (const [key, value] of Object.entries(current_page_other_languages)) {
    var link = document.querySelector('a[data-lang_code="'+value.code+'"]');
    link.href = value.url;
    link.style.display = 'block';
  }
}
