import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class OurWorkProcess {
  constructor(container) {
    
    if (!container) return
    
    this.DOM = { container }
    this.DOM.buttons = this.DOM.container.querySelectorAll('.process--timeline button')
    this.DOM.cells = this.DOM.container.querySelectorAll('.process--timeline .head-cell')
    this.DOM.steps = this.DOM.container.querySelectorAll('.process--timeline .process--step')
    
    if (!this.DOM.buttons && !this.DOM.buttons.length) return

    this.scrollTriggers = []

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 992
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setEvents()
  }

  setEvents() {
    const { container, cells, steps } = this.DOM

    this.mm.add(this.responsiveObj, context => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        steps.forEach((step, index) => {
          const st = ScrollTrigger.create({
            trigger: container,
            start: `${index*10}% top`,
            onEnter: () => {
              cells[index].classList.add('--active')
              step.classList.add('--active')
            },
            onEnterBack: () => {
              cells[index].classList.add('--active')
              step.classList.add('--active')
            },
            onLeave: () => {
              cells[index].classList.remove('--active')
              step.classList.remove('--active')
            },
            onLeaveBack: () => {
              cells[index].classList.remove('--active')
              step.classList.remove('--active')
            },
          })

          this.scrollTriggers.push(st)
        })
      } else {
        steps.forEach(step => step.classList.remove('--active'))
        cells.forEach(cell => cell.classList.remove('--active'))
      }
    })
  }

  destroy() {
    this.scrollTriggers && this.scrollTriggers.length && this.scrollTriggers.forEach(st => st.kill())
  }
}
