import { domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import autoComplete from "@tarekraafat/autocomplete.js";

export default class Search {
  constructor() {
    if (!domStorage.search) return

    // DOM
    this.DOM = {
      openButton: domStorage.search.querySelector('.open-search'),
      closeButton: domStorage.search.querySelector('.close-search'),
      popup: domStorage.search.querySelector('.popup'),
      form: domStorage.search.querySelector('form'),
      input: domStorage.search.querySelector('form input'),
      searchSeeMoreButton: domStorage.search.querySelector('#search-see-more')
    }
    this.timer = false;
    this.run = false;
    this.page = 0;
    this.searchRunning = false;
    this.listItems = [];

    this.urlajax = this.DOM.form.dataset.ajaxurl;
    this.search_autocompletion_urlajax = this.DOM.input.dataset.ajaxurl;

    this.DOM.resultsTag = this.DOM.form.querySelector('#results');


    this.buffer = '';
    this.bufferResults = [];
    this.DOM.autoCompleteJS = new autoComplete({
      highlight: true,
      data: {
        src: async (query) => {
          var tmp = [];
          if (this.searchRunning == false && this.listItems.length) {
            tmp = this.listItems.slice();
            this.listItems = [];
          }
          return tmp;
        }
      }
    });
    document.querySelector("#autoComplete").addEventListener("selection",  (event) => {
      this.DOM.input.value = event.detail.selection.value;
      this.page = 0;
      this.run = true;
      this.runSearch();
    });

    this.DOM.input.addEventListener('keydown', (e) => {
      if (e.key == 'Enter') {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = false;
        }
        this.page = 0;
        this.runSearch();
      } else {
        if (this.timer)
          clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.timer = false;
          this.runAutocompletion(e.target.value);
        }, 1000);
      }
    })

    // Events
    this.openPopup = this.openPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)

    this.DOM.openButton.addEventListener('click', this.openPopup)
    this.DOM.closeButton.addEventListener('click', this.closePopup)

    this.init();
  }

  init() {
    this.DOM.form.addEventListener('submit', (e) => {
      //e.preventDefault();
      //this.runSearch();
    });
    this.DOM.searchSeeMoreButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.page++;
      this.runSearch();
    });
  }

  openPopup(e) {
    e && e.preventDefault()

    this.tlIn = gsap.timeline({
      defaults: { ease: 'expo.inOut', duration: 1 },
      onStart: () => {
        // Stop smooth scroll
        viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()

        // Overflow on body
        document.body.classList.add('--oh')
      },
      onComplete: () => {
        // Focus input
        this.DOM.input.focus()

        gsap.set(this.DOM.popup.querySelector('.container'), { clearProps: 'y,opacity' })
        gsap.set(this.DOM.closeButton, { clearProps: 'opacity' })
      }
    })
    .to(this.DOM.popup, { y: 0 }, 0)
    .fromTo(this.DOM.popup.querySelector('.container'), { y: 50, opacity: 0 }, { y: 0, opacity: 1, ease: 'power3.out', duration: 0.75 }, 0.75)
    .fromTo(this.DOM.closeButton, { opacity: 0 }, { opacity: 1 }, 1)

    return this.tlIn
  }

  closePopup(e) {
    e && e.preventDefault()

    this.tlOut = gsap.timeline({
      defaults: { ease: 'expo.inOut', duration: 1.25 },
      onStart: () => {
        // Blur input
        this.DOM.input.blur()
      },
      onComplete: () => {

        // Start smooth scroll
        viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()

        // Overflow on body
        document.body.classList.remove('--oh')

        gsap.set(this.DOM.popup.querySelector('.container'), { clearProps: 'opacity' })
        gsap.set(this.DOM.popup, { clearProps: 'y' })
        gsap.set(thi.DOM.closeButton, { clearProps: 'opacity' })
      }
    })
    .to(this.DOM.popup.querySelector('.container'), { opacity: 0, ease: 'power3.out', duration: 0.75 }, 0)
    .to(this.DOM.popup, { y: '-100%' }, 0)
    .to(this.DOM.closeButton, { opacity: 0 }, 0)

    return this.tlOut
  }

  onSuccess(responseText) {
    const { searchedProducts, productPagination, totalProducts } = this.DOM
    const json = JSON.parse(responseText);
    if (this.page == 0)
      this.DOM.resultsTag.innerHTML = json.postGrid_html;
    else
      this.DOM.resultsTag.innerHTML += json.postGrid_html;

    if (json.total_pages - 1 == this.page) {
      this.DOM.searchSeeMoreButton.style.display = 'none';
    } else {
      this.DOM.searchSeeMoreButton.style.display = 'block';
    }
    /*
    searchedPost.innerHTML = json.productGrid_html;
    productPagination.innerHTML = json.productPagination_html;
    totalProducts.innerHTML = json.totalProducts;*/
    //this.initPaginationListeners();
  }

  displayAutocompletion(responseText) {
    if (this.searchRunning == false) {
      const json = JSON.parse(responseText);
      this.listItems = json;
      this.DOM.autoCompleteJS.start();
    }
  }

  runAutocompletion(query) {
    const formData = new FormData();
    formData.append('search_in_all', query);

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.search_autocompletion_urlajax, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        switch (xhr.status) {
          case 200:
            this.displayAutocompletion(xhr.responseText)
            break
          case 422:
            break
          default:
          //this.onServerError()
        }
      }
    }
    xhr.send(formData);
  }

  runSearch() {
    this.searchRunning = true;
    const formData = new FormData();
    formData.append('search_in_all', this.DOM.input.value);
    formData.append('page', this.page);

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.urlajax, true)
    xhr.onreadystatechange = () => {
      this.searchRunning = false;
      if (xhr.readyState === XMLHttpRequest.DONE) {
        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            break
          default:
            //this.onServerError()
        }
      }
    }
    xhr.send(formData);
  }

  destroy() {
    this.tlIn && this.tlIn.kill()
    this.tlOut && this.tlOut.kill()
  }
}
