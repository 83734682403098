import { isDEVMODE, viewStorage } from '../_globals'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)

export default class Anchors {

  constructor(container) {

    this.DOM = { container: container }
    this.DOM.anchorsLinks = this.DOM.container.querySelectorAll('[data-anchor]')
    if (this.DOM.anchorsLinks.length === 0) return

    this.init()
    this.addEvents()

  }

  init() {
  }

  addEvents() {
    const { anchorsLinks } = this.DOM

    this.onAnchorClick = this.onAnchorClick.bind(this)

    // Add Click Events
    anchorsLinks.forEach((item) => item.addEventListener('click', this.onAnchorClick))

  }

  onAnchorClick(e) {

    e.preventDefault()

    const { container } = this.DOM
    const { currentTarget } = e

    // anchors active
    if (container.querySelector('.--active')) container.querySelector('[data-anchor].--active').classList.remove('--active')
    currentTarget.classList.add('--active')

    const targetAnchor = currentTarget.getAttribute('href')
    const targetOffset = currentTarget.dataset.offset

    const { hasSmoothScroll, viewScroll } = viewStorage
    if (hasSmoothScroll) viewScroll.scroll.scrollTo(targetAnchor, { offset: targetOffset ? targetOffset * -1 : 0, duration: 0.8, easing: (t) => t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2 })
    else gsap.to(window, {duration: 1.8, scrollTo: { y: targetAnchor, offsetY: targetOffset ? targetOffset : 0, autoKill: false }, overwrite: 'all', ease: 'expo.inOut' })

  }

  destroy() {

    const { anchorsLinks } = this.DOM
    anchorsLinks.forEach((item) => item.addEventListener('click', this.onAnchorClick))

  }
}
