import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknPlyr from '../modules/wkn-plyr'
import VimeoPlayer from '../modules/vimeo-player'
import DiscoverMoreBrands from '../modules/discover-more-brands'

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      plyr: viewStorage.current.querySelector('.plyr__container'),
      vimeo: viewStorage.current.querySelector('.VimeoPlayer'),
      sectionEcosystem: viewStorage.current.querySelector('.section--ecosystem')
    }

    this.init()
  }

  init() {
    const { 
      plyr = null,
      vimeo = null,
      sectionEcosystem = null 
    } = this.DOM

    // add class header
    document.body.classList.add('homepage');

    // brands
    if (sectionEcosystem) this.DiscoverMoreBrandsModule = new DiscoverMoreBrands(sectionEcosystem)

    // init plyr
    if (plyr) this.plyr = new WknPlyr(plyr) 

    // Init Vimeo
    if (vimeo) this.vimeoPlayer = new VimeoPlayer(vimeo)
  }

  onLeaveCompleted() {
    this.plyr && this.plyr.destroy()
    this.DiscoverMoreBrandsModule && this.DiscoverMoreBrandsModule.destroy()
    document.body.classList.remove('homepage')

    this.vimeoPlayer && this.vimeoPlayer.destroy()
  }
}
