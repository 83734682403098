import { Renderer } from "@unseenco/taxi";
import { isDEVMODE, viewStorage } from "../_globals";
import Anchors from '../modules/anchors'

export default class DefaultPageRenderer extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener("DOMContentLoaded", onDOMContentLoaded);
      this.onEnter();
    };

    document.addEventListener("DOMContentLoaded", onDOMContentLoaded);
  }

  onEnter() {
    this.DOM = {
      navAnchors: viewStorage.current.querySelector(".page__header--container nav")
    };

    console.log(this.DOM.navAnchors)

    if (this.DOM.navAnchors) this.anchors = new Anchors(this.DOM.navAnchors);
  }

  init() {}

  onLeaveCompleted() {
    this.anchors && this.anchors.destroy();
  }
}
