import gsap from 'gsap'
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper'
Swiper.use([Navigation, Pagination, Scrollbar])

export default class SteeringCommitteeSwiper {
  constructor(container) {
    this.DOM = { container }
    this.DOM.swiperNavigation = this.DOM.container.parentNode.querySelector('.swiper-navigation')

    this.swiper = null

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 992
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setSwiper()
  }

  setSwiper() {
    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        this.enableSwiper()
      } else {
        if (this.swiper) this.swiper.destroy(true, true)
      }
    })
  }

  enableSwiper() {
    const { container, swiperNavigation } = this.DOM

    this.navigation = false

    if (swiperNavigation) {
      this.navigation = {
        prevEl: container.parentNode.querySelector('.prev'),
        nextEl: container.parentNode.querySelector('.next')
      }
    }

    this.swiper = new Swiper(container, {
      slidesPerView: 1,
      navigation: this.navigation,
      autoHeight: true
    })
  }

  destroy() {
    if (this.swiper) this.swiper.destroy(true, true)
  }
}
