import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import gsap from 'gsap'

export default class ContentLibrary extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      ContentLibrary: viewStorage.current,
      ContentLibraryContainer: viewStorage.current.querySelector('.section--list-content')
    };
    this.urlajax = viewStorage.current.dataset.urlajax;
    this.page = 1;
      var json_data = document.getElementById('json_data');
      json_data = JSON.parse(json_data.innerHTML);
    if (json_data.languages.current == 'en')
      this.term_slug = 'article';
    else
      this.term_slug = 'article-fr';
    this.init()
  }

   init() {
    this.menuNavPosts = viewStorage.current.querySelectorAll('.posts-list--menu-item')
    this.PostsLists = viewStorage.current.querySelectorAll('.posts-list--items-wrapper')

    // Ecosystem toggle posts list
    this.onTabClick = this.onTabClick.bind(this)
    this.menuNavPosts.forEach(tab => tab.addEventListener('click',this.onTabClick))
    this.runSearch();
  }

  onSuccess(responseText) {
    const { ContentLibrary, ContentLibraryContainer } = this.DOM;
    const json = JSON.parse(responseText);

    this.PostsLists.forEach(tab => tab.classList.remove('--active'))

    ContentLibraryContainer.classList.remove('--loading')

    const currentListPosts = document.getElementById('posts--'+this.term_slug);
    currentListPosts.classList.add('--active');
    currentListPosts.innerHTML = json.listPosts_html;

    const postsListItems = currentListPosts.querySelector('.posts-list--items')
    const items = currentListPosts.querySelectorAll('.posts-list--item')

    if (items.length <= 3) {
      items.length % 2 === 0 ?
        postsListItems.classList.add('--even') :
        postsListItems.classList.add('--odd')
    }

    gsap.from(items, { yPercent: 20, opacity: 0, stagger: 0.03, clearProps: 'all', ease:'power4.out', duration: 0.8 })
    this.initPaginationListeners();
  }

  initPaginationListeners() {
    let buttons = document.querySelectorAll('.more-post');
    buttons.forEach((button, index) => {
      const m = button.addEventListener('click', (e) => {
        e.preventDefault();
        this.page = this.page + 1;
        this.runSearch();
      });
    });
  }

  runSearch() {
    const { ContentLibraryContainer } = this.DOM

    ContentLibraryContainer.classList.add('--loading')

    const formData = new FormData();
    formData.append('page', this.page);
    formData.append('term_slug', this.term_slug);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.urlajax, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            break
          default:
            this.onServerError()
        }
      }
    }
    xhr.send(formData);
  }

  onTabClick(e) {
    this.menuNavPosts.forEach(
      tab => tab.classList.remove('--active')
    )
    e.currentTarget.classList.add('--active')
    const { slug } = e.currentTarget.dataset
    this.term_slug = slug;
    this.page = 1;
    this.runSearch();
  }

  onLeaveCompleted() {
    // this.plyr.destroy()
  }
}
