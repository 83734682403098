export default class TableOfContents {
  constructor({ element, section }) {
    if (!element || !section) return

    this.DOM = {
      element,
      section
    }

    this.timeout = 0

    // Initialization
    this.initTableOfContents()

    // Events
    this.onClick = this.onClick.bind(this)
    this.onScroll = this.onScroll.bind(this)

    this.DOM.element.addEventListener('click', this.onClick)
    window.addEventListener('scroll', this.onScroll, { passive: true })
  }

  /**
   * Initialization
   */

  initTableOfContents() {
    const headings = this.DOM.section.querySelectorAll('h1, h2, h3, h4, h5, h6')
    
    headings.forEach((heading, index) => {
      if (!heading.id) {
        heading.id = `heading-${index}`
      }

      const listItem = document.createElement('li')
      const link = document.createElement('a')
      
      link.textContent = heading.textContent
      link.href = `#${heading.id}`
      link.classList.add(`toc-level-${heading.tagName.toLowerCase()}`)
      
      listItem.appendChild(link)
      
      this.DOM.element.appendChild(listItem)
    })
  }

  /**
   * Events
   */

  onClick(e) {
    if (e.target.tagName === 'A') {
      e.preventDefault()
      const targetId = e.target.getAttribute('href').slice(1)
      const targetElement = document.getElementById(targetId)
      
      if (targetElement) {
        const offset = 20
        const elementPosition = targetElement.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - offset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })

        history.pushState(null, null, `#${targetId}`)
      }
    }
  }

  onScroll(e) {
    if (this.timeout) {
      window.cancelAnimationFrame(this.timeout)
    }

    this.timeout = window.requestAnimationFrame(() => {
      const headings = this.DOM.section.querySelectorAll('h1, h2, h3, h4, h5, h6')
      let currentHeading

      headings.forEach(heading => {
        const rect = heading.getBoundingClientRect()

        if (rect.top <= 100) {
          currentHeading = heading
        }
      })

      // Update active class
      const links = this.DOM.element.querySelectorAll('a')

      links.forEach(link => {
        link.classList.remove('--active')

        if (currentHeading && link.getAttribute('href') === `#${currentHeading.id}`) {
          link.classList.add('--active')
        }
      })
    })
  }

  destroy() {
    if (this.timeout) {
      window.cancelAnimationFrame(this.timeout)
    }

    this.DOM.element.removeEventListener('click', this.onClick)
    window.removeEventListener('scroll', this.onScroll, { passive: true })
  }
}