import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'
import { CountUp } from '../modules/countUp.js'
import WknPlyr from '../modules/wkn-plyr'
import VimeoPlayer from '../modules/vimeo-player'

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      swiperGallery: viewStorage.current.querySelector('.swiperGallery'),
      swiperCitations: viewStorage.current.querySelector('.swiperCitations'),
      plyr: viewStorage.current.querySelector('.plyr__container'),
      vimeo: viewStorage.current.querySelector('.VimeoPlayer'),
      numbersCol : viewStorage.current.querySelector('.section--numbers .col')
    }
  
    this.init()
  }

  countStart(){
      const counters = document.querySelectorAll(".js-count-up"),
            options = {
              useEasing: true,
              useGrouping: true,
              separator: " ",
              decimal: "."
            };

      counters.forEach( (item) => {
        const value = item.dataset.value;
        const counter = new CountUp(item, value, options);
        counter.start();
      });
  }

  init() {
    const { 
      numbersCol = null,
      vimeo = null,
      swiperGallery = null, 
      swiperCitations = null,
      plyr = null
    } = this.DOM

    if (swiperGallery) this.mySwiperGallery = new WknSwiper(swiperGallery)
    if (swiperCitations) this.mySwiper = new WknSwiper(swiperCitations)
    if (plyr) this.plyr = new WknPlyr(plyr)
    if (vimeo) this.vimeoPlayer = new VimeoPlayer(vimeo) 

    /* if (numbersCol) {
      new Waypoint({
        element: numbersCol,
        handler: () => {
          this.countStart()
          //this.destroy() //for once
        },
        offset: '50%'
      });
    } */
  }

  onLeaveCompleted() {
    this.mySwiper && this.mySwiper.destroy()
    this.mySwiperGallery && this.mySwiperGallery.destroy()
    this.plyr && this.plyr.destroy()
    this.vimeoPlayer && this.vimeoPlayer.destroy()
  }
}
