import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Form from '../modules/form';
import Accordeons from '../modules/accordeons'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.init()
  }


  init() {
    this.myForm = new Form(viewStorage.current.querySelector('.ContactForm'))

    this.toggleAccordeonContentMenu = viewStorage.current.querySelectorAll('.content-nav-menu--item');
    this.accordeonContainers = viewStorage.current.querySelectorAll('.notMobile .accordeon_container')

    this.accordeonsModule = new Accordeons(viewStorage.current)
    
    this.toggleAccordeonContentMenu.forEach(
      navItem => {
        const dataNavMenu = ""
        navItem.addEventListener('click', (e) => {
          this.toggleAccordeonContentMenu.forEach(
            navItem => {
              navItem.classList.remove('--active')
              this.accordeonContainers.forEach(
                accordeonContainer => {
                  accordeonContainer.classList.remove('--active')
                }
              )
            }
          )
          e.currentTarget.classList.add('--active')
          const dataNavMenu = e.currentTarget.getAttribute('data-nav-menu')
          viewStorage.current.querySelector('.notMobile .accordeon_container[data-acc="'+dataNavMenu+'"]').classList.add('--active')

          ScrollTrigger.refresh()
        })
      }
    )
  }

  onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    if (this.myForm) this.myForm.destroy();
    this.accordeonsModule && this.accordeonsModule.destroy()
  }
}
