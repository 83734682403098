import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknPlyr from '../modules/wkn-plyr'
import WknSwiper from '../modules/wkn-swiper'

export default class DefaultPageRenderer extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      plyrContainer: viewStorage.current.querySelector('.plyr__container'),
      swiperCitations: viewStorage.current.querySelector('.swiperCitations')
    }

    this.init()
  }

  init() {
    const {
      plyrContainer = null,
      swiperCitations = null
    } = this.DOM

    if (plyrContainer) this.plyr = new WknPlyr(plyrContainer)
    if (swiperCitations) this.mySwiper = new WknSwiper(swiperCitations)
  }

  onLeaveCompleted() {
    this.plyr && this.plyr.destroy()
    this.mySwiper && this.mySwiper.destroy()
  }
}
