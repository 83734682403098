/*
    Load Plugins / Functions
-------------------------------------------------- */
import { Core } from "@unseenco/taxi";
import { globalStorage } from "./_globals";
import { onLeave, onEnter, onEnterCompleted } from "./_events";

/* --- Renderers --- */
import Page from "./pages/page";
import Homepage from "./pages/homepage";
import AboutUs from "./pages/about-us";
import OurWork from "./pages/our-work";
import OurEcosystem from "./pages/our-ecosystem";
import OurWorkChild from "./pages/our-work-child";
import HowToEngage from "./pages/how-to-engage";
import FAQsContact from "./pages/faqs-contact";
import ContentLibrary from "./pages/content-library";
import Legals from "./pages/legals";
import PageComponents from "./pages/components";
import Post from "./pages/single";
import PageCustomPassword from "./pages/page-custom-password"

/* --- Transitions --- */
import PageTransitionDefault from "./transitions/page-transition-default";

/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links:
    "a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)",
  renderers: {
    default: Page,
    pageHomepage: Homepage,
    pageAboutUs: AboutUs,
    pageOurWork: OurWork,
    pageOurEcosystem: OurEcosystem,
    pageOurWorkChild: OurWorkChild,
    pageHowToEngage: HowToEngage,
    pageFAQsContact: FAQsContact,
    pageContentLibrary: ContentLibrary,
    pageLegals: Legals,
    pageComponents: PageComponents,
    post: Post,
    pageCustomPassword: PageCustomPassword,
  },
  transitions: {
    default: PageTransitionDefault
  }
});

/* --- Global Events --- */
globalStorage.taxi.on("NAVIGATE_IN", ({ to, trigger }) => onEnter(to, trigger));
globalStorage.taxi.on("NAVIGATE_OUT", ({ from, trigger }) =>
  onLeave(from, trigger)
);
globalStorage.taxi.on("NAVIGATE_END", ({ to, from, trigger }) =>
  onEnterCompleted(to, from, trigger)
);
