

export default class Accordeons {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.accordeons = this.DOM.container.querySelectorAll('.accordeon_row')

    if (!this.DOM.accordeons && !this.DOM.accordeons.length) return

    this.setEvents()
  }

  setEvents() {
    const { accordeons } = this.DOM

    this.toggleAccordeon = this.toggleAccordeon.bind(this)

    accordeons.forEach(accordeon => {
      const button = accordeon.querySelector('button.accordeon_row--label')
      const content = accordeon.querySelector('.accordeon_row--content')

      if (!button) return

      // We need to adapt the element that already has to '--active' className
      if (accordeon.classList.contains('--active')) {
        if (content.style.maxHeight) {
          content.style.maxHeight = null
        } else {
          content.style.maxHeight = `${content.scrollHeight}px`
        }
      }

      button.addEventListener('click', this.toggleAccordeon)
    })
  }

  toggleAccordeon(e) {
    e && e.preventDefault()

    const parent = e.currentTarget.parentNode
    const content = parent.querySelector('.accordeon_row--content')

    if (content.style.maxHeight) {
      content.style.maxHeight = null
    } else {
      content.style.maxHeight = `${content.scrollHeight}px`
    }

    parent.classList.toggle('--active')
  }

  destroy() {
    const { accordeons = null } = this.DOM

    if (!accordeons && !accordeons.length) return

    accordeons.forEach(accordeon => {
      const button = accordeon.querySelector('button.accordeon_row--label')

      if (!button) return

      button.addEventListener('click', this.toggleAccordeon)
    })
  }
}
