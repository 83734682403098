import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class IndustryProgress {
  constructor(container) {
    this.DOM = { container }
    this.DOM.navButtons = this.DOM.container.querySelectorAll('button.industry-progress--menu__item')
    this.DOM.tabs = this.DOM.container.querySelectorAll('.tab')
    this.DOM.activeTab = this.DOM.container.querySelector('.tab.--active')

    if (!this.DOM.navButtons && !this.DOM.navButtons.length) return

    this.incrementNumbersOnScroll()
    this.setEvents()
  }

  incrementNumbersOnScroll() {
    const { container, activeTab } = this.DOM

    this.ST = new ScrollTrigger.create({
      trigger: container,
      start: 'top center',
      end: 'bottom top',
      onEnter: () => {
        const numbers = activeTab.querySelectorAll('.counter')

        numbers.forEach(number => gsap.fromTo(number, { textContent: '0' }, { textContent: number.dataset.number, duration: 1.5, ease: 'power4.inOut', snap: { textContent: 1 } }))
      }
    })
  }

  setEvents() {
    const { navButtons } = this.DOM

    this.displayRelatedTab = this.displayRelatedTab.bind(this)

    navButtons.forEach(button => button.addEventListener('click', this.displayRelatedTab))
  }

  displayRelatedTab(e) {
    e && e.preventDefault() 

    const { tabs, navButtons } = this.DOM
    const currentButtonSlug = e.currentTarget.getAttribute('data-slug')

    // Adapt the active button styles
    navButtons.forEach(button => {
      button.classList.remove('--active')

      e.currentTarget.classList.add('--active')
    })

    // Display the good tab
    tabs.forEach(tab => {
      // We need to hide all the tab
      tab.classList.remove('--active')
      // Then display the active one
      if (tab.dataset.slug === currentButtonSlug) {
        tab.classList.add('--active')
        
        // Animating the numbers
        const numbers = tab.querySelectorAll('.counter')

        if (numbers && numbers.length) {
          numbers.forEach(number => gsap.fromTo(number, { textContent: '0' }, { textContent: number.dataset.number, duration: 1, ease: 'expo.inOut', snap: { textContent: 1 } }))
        }
      }
    })
    
  }

  destroy() {
    this.ST && this.ST.kill()
    this.DOM.navButtons && this.DOM.navButtons.length && this.DOM.navButtons.forEach(button => button.removeEventListener('click', this.displayRelatedTab))
  }
}
