import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknPlyr from '../modules/wkn-plyr'
import WknSwiper from '../modules/wkn-swiper'
import IndustryProgress from '../modules/industry-progress'
import OurWorkProcess from '../modules/our-work-process'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
// import Anchors from '../modules/anchors'
import FlippingCards from '../modules/flipping-cards'
import OurWorkSchema from '../modules/our-work-schema'

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      plyrs: viewStorage.current.querySelectorAll('.plyr__container'),
      ourProcessSection: viewStorage.current.querySelector('.section--process'),
      mainAreasSection: viewStorage.current.querySelector('.section--mainAreas'),
      areasOfActionSwiper: viewStorage.current.querySelector('.section--areas-of-action .swiper'),
      industryProgressSection: viewStorage.current.querySelector('.section--industry-progress'),
      toolsAndRessourcesType: document.querySelector('#toolsAndRessourcesType'),
      toolsAndRessourcesArea: document.querySelector('#toolsAndRessourcesArea'),
      ourWorkSchema: viewStorage.current.querySelector('.OurWorkSchema')
    }

    this.init()
  }

  init() {
    const {
      plyrs = null,
      ourProcessSection = null,
      mainAreasSection = null,
      industryProgressSection = null,
      areasOfActionSwiper = null,
      toolsAndRessourcesType,
      toolsAndRessourcesArea,
      ourWorkSchema = null
    } = this.DOM
    
    this.plyrModules = []
    if (plyrs && plyrs.length) plyrs.forEach(item => this.plyrModules.push(new WknPlyr(item)))
    if (ourProcessSection) this.ourWorkProcessModule = new OurWorkProcess(ourProcessSection)
    if (mainAreasSection) this.flippingCards = new FlippingCards(mainAreasSection)
    if (industryProgressSection) this.IndustryProgressModule = new IndustryProgress(industryProgressSection)
    if (areasOfActionSwiper) this.areasOfActionSwiperModule = new WknSwiper(areasOfActionSwiper, { slidesPerView: 'auto' })
    if (ourWorkSchema) this.ourWorkSchemaModule = new OurWorkSchema(ourWorkSchema)

    this.tool_ressource_type_term = '';
    this.tipping_point_cpt = '';
    this.urlajax = viewStorage.current.dataset.ajaxurl;

    toolsAndRessourcesType.addEventListener('change', (e) => {
      this.tool_ressource_type_term = e.target.value;
      this.runSearch();
    });
    toolsAndRessourcesArea.addEventListener('change', (e) => {
      this.tipping_point_cpt = e.target.value;
      this.runSearch();
    });
    this.runSearch();
  }

  onSuccess(responseText) {
    const json = JSON.parse(responseText);

    const currentListPosts = document.querySelector('.tools-and-ressources--results');
    currentListPosts.innerHTML = json.listPosts_html;

    ScrollTrigger.refresh()
  }

  runSearch() {
    const formData = new FormData();
    formData.append('tool_ressource_type_term', this.tool_ressource_type_term);
    formData.append('tipping_point_cpt', this.tipping_point_cpt);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.urlajax, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            break
          default:
//            this.onServerError()
        }
      }
    }
    xhr.send(formData);
  }

  onLeaveCompleted() {
    this.plyrModules && this.plyrModules.length && this.plyrModules.forEach(item => item.destroy())
    this.ourWorkProcessModule && this.ourWorkProcessModule.destroy()
    this.flippingCards && this.flippingCards.destroy()
    this.areasOfActionSwiperModule && this.areasOfActionSwiperModule.destroy()
    this.mySwiper && this.mySwiper.destroy()
    this.ourWorkSchemaModule && this.ourWorkSchemaModule.destroy()
  }
}
