import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const { intro, body, header } = domStorage
  const { hasSmoothScroll, viewScroll } = viewStorage

  const introBrand = intro.querySelector('.Brand')
  const introInner = intro.querySelector('.inner')

  gsap
    .timeline({
      delay: 0.2,
      defaults: { duration: 1, ease: 'power3.inOut' },
      onStart: () => {
        body.classList.remove('--preloading')
        body.classList.remove('--loading')

        // Stop Scroll
        if (hasSmoothScroll) viewScroll.scroll.stop()
      },
      onComplete: () => {
        ScrollTrigger.refresh()
        body.classList.remove('--animating')
        body.classList.remove('--intro')

        // Remove Loader
        if (intro) intro.remove()

        // Start Scroll
        if (hasSmoothScroll) viewScroll.scroll.start()

        // Cleaning styles
        gsap.set(introBrand, { clearProps: 'all' })
        gsap.set(introInner, { clearProps: 'all' })
        gsap.set(intro, { clearProps: 'all' })

        globalStorage.firstLoad = false
      }
    })
    .fromTo(introBrand, { opacity: 0, scale: 1.2 }, { opacity: 1, scale: 1, ease: 'power3.out' }, 0)
    .fromTo(introInner, { y: 0 }, { y: '75%' }, 0.8)
    .fromTo(intro, { y: 0 }, { y: '-100%' }, 0.8)
    .add(pageIntro(), 0.9)
}
