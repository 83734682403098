

export default class Committees {
  constructor(section) {
    // DOM
    this.DOM = { section }
    this.DOM.itemsDescriptionButtons = this.DOM.section.querySelectorAll('.committee--item .committee--item__text button')
    this.DOM.toggleCommittee = this.DOM.section.querySelectorAll('.toggle-committee--menu_btn')
    this.DOM.resultsCommittee = this.DOM.section.querySelectorAll('.toggle-committee--tab')

    // Events
    this.openTab = this.openTab.bind(this)
    this.toggleDescription = this.toggleDescription.bind(this)

    this.DOM.toggleCommittee && this.DOM.toggleCommittee.length && this.DOM.toggleCommittee.forEach(button => button.addEventListener('click', this.openTab))
    this.DOM.itemsDescriptionButtons && this.DOM.itemsDescriptionButtons.length && this.DOM.itemsDescriptionButtons.forEach(button => button.addEventListener('click', this.toggleDescription))
  }

  openTab(e) {
    e && e.preventDefault()

    // Remove active classNames
    this.DOM.toggleCommittee.forEach(tab => tab.classList.remove('--active'))
    this.DOM.resultsCommittee.forEach(tab => tab.classList.remove('--active'))

    // Active button
    e.currentTarget.classList.add('--active')

    const currentCommittee = e.currentTarget.getAttribute('data-tab')
    const currentCommitteeResults = document.getElementById(currentCommittee)
    currentCommitteeResults.classList.add('--active')
  }

  toggleDescription(e) {
    e && e.preventDefault()

    const currentItem = e.currentTarget.parentNode.parentNode.parentNode
    const currentItemDescription = currentItem.querySelector('.description')
    const currentGridItems = currentItem.parentNode

    // Show/Hide description
    currentItem.classList.toggle('show-description')

    if (currentItem.classList.contains('show-description')) {
      currentItemDescription.style.maxHeight = `${currentItemDescription.scrollHeight}px`

      // Adapt the alignment of the grid parent
      currentGridItems.classList.add('align-start')
    } else {
      currentItemDescription.style.maxHeight = '0'

      // Adapt the alignment of the grid parent
      setTimeout(() => currentGridItems.classList.remove('align-start'), 450)
    }
  }

  destroy() {
    this.DOM.toggleCommittee && this.DOM.toggleCommittee.length && this.DOM.toggleCommittee.forEach(button => button.removeEventListener('click', this.openTab))
    this.DOM.itemsDescriptionButton && this.DOM.itemsDescriptionButton.length && this.DOM.itemsDescriptionButton.forEach(button => button.removeEventListener('click', this.toggleDescription))
  }
 }