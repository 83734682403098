// This module is used in the Homepage

import gsap from 'gsap'

export default class DiscoverMoreBrands {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.partnersContainer = this.DOM.container.querySelector('.partners-name')
    this.DOM.loadMoreButton = this.DOM.container.querySelector('#LoadMoreBrands > .Btn')

    if (!this.DOM.partnersContainer && !this.DOM.partnersContainer.length) return

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setEvents()
  }

  setEvents() {
    const { partnersContainer, loadMoreButton } = this.DOM

    this.toggleDisplay = this.toggleDisplay.bind(this)

    loadMoreButton.addEventListener('click', this.toggleDisplay)

    // If the screen > 768px, we remove the button and the events
    this.mm.add(this.responsiveObj, context => {
      const { isMobile } = context.conditions

      if (isMobile) {
        // We need to check if the content is big enough to add the events
        if (partnersContainer.offsetHeight > 350) {
          partnersContainer.classList.add('--too-big')
        } else {
          partnersContainer.classList.remove('--too-big')
        }
      } else {
        partnersContainer.classList.remove('--too-big')
        partnersContainer.classList.remove('--active')
      }
    })
  }

  toggleDisplay(e) {
    e && e.preventDefault()

    const { partnersContainer } = this.DOM

    partnersContainer.classList.toggle('--active')
  }

  destroy() {
    const { loadMoreButton = null } = this.DOM

    if (loadMoreButton) loadMoreButton.removeEventListener('click', this.toggleDisplay)
  }
}
