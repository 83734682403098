import { Renderer } from '@unseenco/taxi';
import {globalStorage, isDEVMODE, viewStorage} from '../_globals'

export default class DefaultPageRenderer extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      ContentLibrary: document.querySelector('.OurEcosystem'),
    };
    this.urlajax = document.querySelector('.OurEcosystem').dataset.urlajax;
    this.page = 1;
    // this.term_slug = 'framework-organisations';
    var json_data = document.getElementById('json_data');
    json_data = JSON.parse(json_data.innerHTML);
    this.term_slug = 'members';
    this.init()
  }

  onSuccess(responseText) {
    const { ContentLibrary } = this.DOM;
    const json = JSON.parse(responseText);

    const currentListResults = document.getElementById('results--'+this.term_slug);
    currentListResults.innerHTML = json.listPosts_html;

    this.initPaginationListeners();
  }

  initPaginationListeners() {
    let buttons = document.querySelectorAll('.more-post');
    buttons.forEach((button, index) => {
      const m = button.addEventListener('click', (e) => {
        e.preventDefault();
        this.page = this.page + 1;
        this.runSearch();
      });
    });
  }

  runSearch() {
    const formData = new FormData();
    formData.append('page', this.page);
    formData.append('term_slug', this.term_slug);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.urlajax, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            break
          default:
            this.onServerError()
        }
      }
    }
    xhr.send(formData);
  }

  init() {
    this.menuNavResults = viewStorage.current.querySelectorAll('.results-list--menu-item')
    this.resultsLists = viewStorage.current.querySelectorAll('.results-list--items-wrapper')
    this.resultsIntros = viewStorage.current.querySelectorAll('.results-intro')

    // Ecosystem toggle results list
    const currentType = ""
    const currentIntroResults = ""
    const currentListResults = ''
    this.menuNavResults.forEach(
      tab => {
        tab.addEventListener('click', (e) => {
          this.menuNavResults.forEach(
            tab => tab.classList.remove('--active')
          )
          this.resultsLists.forEach(
            tab => tab.classList.remove('--active')
          )
          this.resultsIntros.forEach(
            item => item.classList.remove('--active')
          )
          e.currentTarget.classList.add('--active')
          const currentType = e.currentTarget.getAttribute('data-slug')
          const currentListResults = document.getElementById('results--'+currentType)
          const currentIntroResults = document.getElementById('results-intro--'+currentType)
          currentListResults.classList.add('--active')
          currentIntroResults.classList.add('--active')
          this.term_slug = currentType;
          this.page = 1;
          this.runSearch();
        })
      }
    )

    // opening menu depending anchor
    const url = window.location.href;
    const anchor = url.substr(url.indexOf("#"));
    const anchor2 = anchor.replace("#", "");
    var currentListResults2 = '';
    var currentIntroResults2 = '';
    var currentMenuResults = '';
    if ((anchor2 != '/') && (anchor2 != '') && anchor2 != undefined) {
      this.menuNavResults.forEach(
        tab => tab.classList.remove('--active')
      )
      this.resultsLists.forEach(
        tab => tab.classList.remove('--active')
      )
      this.resultsIntros.forEach(
        item => item.classList.remove('--active')
      )
      var currentListResults2 = viewStorage.current.querySelector('#results--'+anchor2)
      var currentIntroResults2 = document.getElementById('results-intro--'+anchor2)
      var currentMenuResults = viewStorage.current.querySelector('.results-list--menu-item[data-slug="'+anchor2+'"]')
      currentListResults2.classList.add('--active')
      currentIntroResults2.classList.add('--active')
      currentMenuResults.classList.add('--active')
    }

    this.runSearch();
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    // this.plyr.destroy()
  }
}
