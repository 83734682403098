/* global gtag */

/* --- Load Plugins / Functions --- */
import { isDEVMODE, globalStorage, viewStorage, domStorage } from './_globals'
import { updatePageInfo, updateLanguages } from './_functions'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollToPlugin)
import { GlobalIntro } from './animations/global-intro'

import viewScroll from './modules/view-scroll.js'
import viewAnims from './modules/view-anims.js'
import viewPrllx from './modules/view-prllx.js'
import viewInView from './modules/view-inview.js'
import viewModals from './modules/view-modal'

/* --- DOMContentLoaded Function --- */
export const onReady = () => {

  updatePageInfo(document);
  updateLanguages();

  const { body } = domStorage

  viewStorage.viewScroll = new viewScroll()
  viewStorage.viewPrllx = new viewPrllx(body)
  viewStorage.viewInView = new viewInView(body)
  viewStorage.viewModals = new viewModals(body)
  viewStorage.viewAnims = new viewAnims(body)

  const { hasSmoothScroll } = viewStorage

  setTimeout(() => window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop), 100)
  ScrollTrigger.refresh()

  // Anchor
  if(window.location.hash) {
    const hash = window.location.hash.substring(1)
    const anchorEl = viewStorage.current.querySelector(`#${hash}`)

    if (!anchorEl) return

    gsap.to(window, {duration: 1.8, scrollTo: anchorEl, overwrite: 'all', ease: 'expo.inOut' })
  }

  // Intro
  GlobalIntro()

}

export const onEnter = (to, trigger) => {


  if (typeof Matomo === 'undefined') {
    console.log('Matomo undefined');
  } else {
    Matomo.getTracker().trackLink();
  }
  updatePageInfo(to.page);
  updateLanguages();
  // LoadingClasses
  const { body } = domStorage
  body.classList.remove('--loading')

  // ScrollTop
  window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop)

  // Update Current View
  viewStorage.current = to.renderer.content

  // Add Page Effects
  const { hasSmoothScroll } = viewStorage
  body.style.overflow = ''

  if (hasSmoothScroll) viewStorage.viewScroll.scroll.start()
  viewStorage.viewPrllx = new viewPrllx()
  viewStorage.viewInView = new viewInView()
  viewStorage.viewModals = new viewModals()
  viewStorage.viewAnims = new viewAnims()

  // FAQ
  // if (viewStorage.current.querySelector('#FAQ')) {
  //   viewStorage.faq = new viewFAQ()
  // }

}

export const onEnterCompleted = (to, from, trigger) => {

  const { hasSmoothScroll, viewScroll } = viewStorage
  const { targetLocation } = globalStorage.taxi

  // Anchor
  if (targetLocation.hasHash) {
    const anchorEl = document.querySelector(window.location.hash)

    if (anchorEl) {
      gsap.to(window, {duration: 1.8, scrollTo: anchorEl, overwrite: 'all', ease: 'expo.inOut' })
    }
  }

  // Analytics
  if (globalStorage.tarteaucitron && globalStorage.tarteaucitron.job.includes('gtag') && typeof gtag !== 'undefined') {
    gtag('config', globalStorage.tarteaucitron.user.gtagUa, {
      'page_path': targetLocation.pathname,
      'page_title': to.page.title,
      'page_location': targetLocation.href
    })
  }

}

export const onLeave = (from, trigger) => {

  // Remove Previous Page Effects
  const { hasInView, hasSticky, hasPrllx, hasModals, hasCursor, hasAnims } = viewStorage

  hasInView && viewStorage.viewInView.destroy()
  hasSticky && viewStorage.viewSticky.destroy()
  hasPrllx && viewStorage.viewPrllx.destroy()
  hasModals && viewStorage.viewModals.destroy()
  hasAnims && viewStorage.viewAnims.destroy()
  hasCursor && globalStorage.Cursor.removeHovers()

  // viewStorage.faq.destroy()

  // LoadingClasses
  const { body } = domStorage
  body.classList.add('--loading')
  body.classList.add('--animating')

  const { menu } = domStorage
  const { menuOpen } = globalStorage

  // Close Menu
  menuOpen && globalStorage.closeMobileMenu()

  // Remove active links
  const { targetLocation } = globalStorage.taxi
  const navLinks = menu.querySelectorAll('.Nav__link')
  navLinks.forEach((link) => {
    if (link.href === targetLocation.raw) link.classList.add('--active')
    else link.classList.remove('--active')
  })

}
