import Vimeo from '@vimeo/player'

export default class VimeoPlayer {
  constructor(video) {
    if (!video) return

    this.DOM = { video }
    this.DOM.iframe = this.DOM.video.querySelector('iframe')
    this.DOM.poster = this.DOM.video.querySelector('.poster')

    this.player = new Vimeo(this.DOM.iframe)

    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)

    this.DOM.poster.addEventListener('click', this.play)

    this.player.on('pause', () => this.pause())
  }

  play(e) {
    e && e.preventDefault()

    if (!this.player) return

    this.player.play()

    this.DOM.video.classList.add('--playing')
  }

  pause(e) {
    e && e.preventDefault()

    this.DOM.video.classList.remove('--playing')
  }

  destroy() {
    this.DOM.poster && this.DOM.poster.removeEventListener('click', this.play)
    this.player && this.player.off('pause', () => this.pause())
  }
}
