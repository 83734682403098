/**
 * @property { HTMLElement }
 */
export default class OurWorkSchema {
  constructor(el) {
    // DOM
    this.DOM = { schema: el }
    this.DOM.allPopupsOpeners = [...this.DOM.schema.querySelectorAll('.popup-opener')]
    this.DOM.allPopups = [...this.DOM.schema.querySelectorAll('.popup')]
    this.DOM.closeButtons = [...this.DOM.schema.querySelectorAll('.popup > button')]

    // If no popup, we cancel all the rest
    if (!this.DOM.allPopups && ! this.DOM.allPopups.length) return

    // Settings
    this.currentPopup = null
    this.schemaClassName = '--show-popup'

    // Events
    this.openPopup = this.openPopup.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.clickOutside = this.clickOutside.bind(this)

    this.DOM.allPopupsOpeners.forEach(opener => opener.addEventListener('click', this.openPopup))
    this.DOM.closeButtons.forEach(button => button.addEventListener('click', this.closePopup))
  }

  openPopup(e) {
    e && e.preventDefault()

    // Add general className to the schema
    this.DOM.schema.classList.add(this.schemaClassName)

    // Get current popup opener
    const currentOpener = e.currentTarget
    // Get the current index to show the good popup
    const currentIndex = this.DOM.allPopupsOpeners.indexOf(currentOpener)
    // Get the current popup 
    this.currentPopup = this.DOM.allPopups[currentIndex]

    // Adapt the styles
    this.currentPopup.classList.add('--active')

    // Close popup on click outside of it (with a delay of 250ms)
    setTimeout(() => document.addEventListener('click', this.clickOutside), 250)
  } 

  closePopup(e) {
    e && e.preventDefault()

    // Hide the currentPopup
    this.currentPopup.classList.remove('--active')

    // Remove general className to the schema
    this.DOM.schema.classList.remove(this.schemaClassName)  
    
    // Remove the event on document
    document.removeEventListener('click', this.clickOutside)
  }

  clickOutside(e) {
    e && e.stopPropagation()

    if (this.currentPopup.contains(e.target)) return

    this.closePopup()
  }

  destroy() {
    this.DOM.allPopupsOpeners && this.DOM.allPopupsOpeners.length && this.DOM.allPopupsOpeners.forEach(opener => opener.removeEventListener('click', this.openPopup))
    this.DOM.closeButtons && this.DOM.closeButtons.length && this.DOM.closeButtons.forEach(button => button.removeEventListener('click', this.closePopup))
  }
}
