export default class FlippingCards {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.cards = this.DOM.container.querySelectorAll('.mainAreas-list--item')

    if (!this.DOM.cards && !this.DOM.cards.length) return

    this.openCard = this.openCard.bind(this)
    this.closeCard = this.closeCard.bind(this)

    this.DOM.cards.forEach(card => {
      const buttonMore = card.querySelector('.front-side > button')
      const buttonBack = card.querySelector('.back-side > button')
      const backSide = card.querySelector('.back-side')

      if (!backSide) return

      buttonMore.addEventListener('click', this.openCard)
      buttonBack.addEventListener('click', this.closeCard)
    })
  }

  openCard(e) {
    e && e.preventDefault()

    const card = e.currentTarget.parentNode.parentNode.parentNode

    card.classList.add('--flipping')
  }

  closeCard(e) {
    e && e.preventDefault()

    const card = e.currentTarget.parentNode.parentNode.parentNode

    card.classList.remove('--flipping')
  }

  destroy() {
    if(!this.DOM.cards && !this.DOM.cards.length) return

    this.DOM.cards.forEach(card => {
      const buttonMore = card.querySelector('.front-side > button')
      const buttonBack = card.querySelector('.back-side > button')
      const backSide = card.querySelector('.back-side')

      if (!backSide) return

      buttonMore.removeEventListener('click', this.openCard)
      buttonBack.removeEventListener('click', this.closeCard)
    })
  }
}
